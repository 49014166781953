<template>
  <div class="app-container">
    <CrudTable ref="table" entity="Configuration" :columns="columns" :allow-create="false" :allow-delete="false" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import crudIndexMixin from '@/mixins/crud-index';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [
        { field: 'code', label: 'configuration.code' },
        'description',
        { field: 'value', label: 'configuration.value' },
        {
          field: 'fromDate',
          label: 'configuration.fromDate',
          type: 'dateTime'
        }
      ]
    };
  }
};
</script>

<style scoped></style>
